<template>
  <div>
    <!-- <v-alert v-if="showAlert" :type="alertType" dismissible>
      {{ alertMessage }}
    </v-alert> -->
    <v-card-text>
      Manage States Here, Can control the state by managing the status
      <!-- <v-subheader class="pa-0">
        Where do you live?
      </v-subheader> -->
      <v-data-table
        v-model="selectedStates"
        :headers="headers"
        :items="allStates"
        fixed-header
        height="550px"
        hide-default-footer
        class="elevation-1"
        :disable-pagination="true"
        :loading="tableLoading"
        loading-text="Loading... Please wait"
        dense
        item-key="id"
        :search="search"
        show-select
      >
        <template v-slot:top>
          <v-divider></v-divider>
          <v-toolbar flat dense>
            <v-toolbar-title>
              <span class="text-h5"> States</span>
            </v-toolbar-title>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              color="error"
              outlined
              :loading="isLoading"
              v-if="selectedStates.length > 0"
              @click="deleteSelectedHandler"
              >Delete Selected</v-btn
            >
            <v-spacer></v-spacer>
            <v-chip class="ma-2" color="green" text-color="white">
              Total
              <v-avatar right class="green darken-4">
                {{ allStates.length }}
              </v-avatar>
            </v-chip>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              style="max-width: 300px"
            ></v-text-field>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" text color="indigo">
                  {{ selectedStatus }} <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="status in ['Active', 'Deleted']"
                  :key="status"
                  @click="changeStatusFilter(status)"
                  dense
                >
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark class="primary" v-bind="attrs" v-on="on">
                  <v-icon left dark> mdi-plus </v-icon>
                  ADD NEW
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add New State</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="state"
                            label="State*"
                            required
                            autofocus
                            :rules="[rules.required('State')]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="postData">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
            small
            @click="changeStatus('state_code', item.state_code, item.status)"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
// import { HollowDotsSpinner } from "epic-spinners";
import { mapState, mapGetters, mapActions } from "vuex";
import rules from "../helpers/validation";
import axios from "axios";
import { baseURL } from "../constant/constant";

export default {
  name: "Home",

  components: {
    // Alert,
    // rules,
    // HollowDotsSpinner,
  },
  data: () => ({
    selectedStatus: "Active", // Default value is 'All'
    search: "",
    tableLoading: false,
    dialog: false,
    moment: moment,
    valid: true,
    state: "",
    rules: rules,
    selectedStates: [],
    isLoading: false,
    headers: [
      {
        text: "Sno",
        value: "index",
        sortable: false,
        width: 10,
      },
      {
        text: "States",
        value: "state_name",
      },
      {
        text: "Status",
        value: "status",
      },
    ],
  }),
  computed: {
    ...mapGetters(["allStates", "alertType", "alertMessage"]),
    ...mapState(["response"]),
  },
  mounted() {
    this.loadStates(this.selectedStatus);
  },
  methods: {
    ...mapActions([
      "addNewState",
      "showAlert",
      "fetchStates",
      "updateEntityStatus",
    ]),
    // getStatusColor(status) {
    //   if (status != "Active") return "red";
    //   else return "green";
    // }
    async changeStatusFilter(status) {
      this.selectedStatus = status;
      await this.loadStates(this.selectedStatus);
    },
    async postData() {
      this.tableLoading = true;
      if (this.validateForm()) {
        const payload = {
          locations: [
            {
              name: this.state,
              vote_limit: 10000,
            },
          ],
        };
        console.log("dsfsdf", payload);
        try {
          await this.addNewState(payload);
        } catch (error) {
          console.log(error);
        } finally {
          await this.fetchStates();
          this.dialog = false;
          this.resetValidationForm();
          this.resetForm();
          this.tableLoading = false;
        }
      } else {
        this.tableLoading = false;
        await this.showAlert({
          type: "error",
          message: "Please fix the form errors",
        });
      }
    },
    deleteSelectedHandler() {
      this.isLoading = true;
      axios
        .delete(`${baseURL}admin/states`, {
          data: {
            ids: this.selectedStates.map((v) => v.id),
          },
        })
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "success",
            message: `Deleted Successfully!`,
          });
          this.selectedStates = [];
          this.loadStates(this.selectedStatus);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$store.dispatch("showAlert", {
            type: "error",
            message: `${err}`,
          });
        });
    },
  },
};
</script>
